import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { useSpring, animated } from "react-spring"
import styled from "styled-components"
import Icon from "../utilities/icons"
import CityGuildsLogo from "../images/city_guilds_logo.jpg"
import ECSLogo from "../images/ecs-logo.jpg"
import NapitLogo from "../images/napit_trustmark.jpg"

const AccreditationBar = styled.div`
  background: #ffffff;
  border-top: 1px solid #d9d9d9;

  .inner-container {
    display: flex;
    padding: 20px 20px;
    align-items: flex-start;
    justify-content:space-between;
    

    @media (max-width:720px) {
      flex-direction:column;
      align-items: center;
    }

  }

  img {
    width: 100px;
    margin-right: 10px;
    display: block;
    align-self:flex-start;
  }

  p {
    margin: 0;
    flex: 1;
    font-size:16px;
  }
`

const FooterContainer = styled.footer`
  background: #ffffff;
  padding: 0 0 20px 0;


  .inner-container {
    border-top: 1px dashed #d9d9d9;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    @media (max-width: 675px) {
      flex-direction: column;
      text-align: center;
    }
  }

  .footer-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .socmed-footer {
    display: flex;
    margin-left: 5px;
  }

  .footer-right {
    a {
      color: #ff7459;
      &:hover {
        color: #ff4126;
      }
    }
  }
`
const ToTopBtn = styled.div`
  position: fixed;
  right: 30px;
  bottom: 60px;
  background: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-bottom: 2px solid ${props => props.theme.orange};
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 5000;
  @media (max-width: 750px) {
    right: 20px;
  }
`

const FooterAccreditaionItem = styled.div`
  width:calc(33.33% - 15px);
  display:flex;
  flex-grow:0;
  justify-content: space-between;

  p {font-size:14px; line-height:22px;}

  img {
    display:block;
    border:1px solid #d9d9d9;
  }

  @media (max-width:720px) {
      width:100%;
      margin-bottom:20px;
      border-bottom:1px dashed #d9d9d9;
      padding-bottom:20px;
      align-items: center;

      &:last-of-type {
        margin-bottom:0;
        border-bottom:0;
        padding-bottom:0;
      }
    }
`

const Footer = ({ }) => {
  useEffect(() => {
    const vpHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    )
    const toTopBtn = document.querySelector(".toTopBtn")

    setInterval(function () {
      var scrollTop =
        window.pageYOffset !== undefined
          ? window.pageYOffset
          : (
            document.documentElement ||
            document.body.parentNode ||
            document.body
          ).scrollTop

      if (scrollTop > vpHeight) {
        toTopBtn.style.display = "flex"
      } else {
        toTopBtn.style.display = "none"
      }
    }, 1000)
  })

  const goToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  return (
    <>
      <AccreditationBar>
        <div className="inner-container">
        <FooterAccreditaionItem>
            <img src={NapitLogo} alt="Napit Trustmark Logo" />
            <p>
            NAPIT approved contractor with Trustmark status. All work is certified and notified to building regs and local authorities under Part P.
            </p>
          </FooterAccreditaionItem>
          <FooterAccreditaionItem>
            <img src={CityGuildsLogo} alt="City and Guilds Logo" />
            <p>
              All electrical work carried out by City and Guilds qualified
              electricians and compliant with BS 7671 Regulations.
            </p>
          </FooterAccreditaionItem>
          <FooterAccreditaionItem>
            <img src={ECSLogo} alt="Electrotechnical Certification Scheme Logo" />
            <p>
              All electricians hold a relevant ECS card and have the skills and competency to complete work to a high standard.
            </p>
          </FooterAccreditaionItem>
        </div>
      </AccreditationBar>
      <FooterContainer>
        <div className="inner-container">
          <div className="footer-center">
            <span>© {new Date().getFullYear()} | MJ Electrical | </span>
            <a
              className="socmed-footer"
              target="_new"
              href="https://www.facebook.com/mj.electrical1"
            >
              <Icon name="facebook" color="#3C5A99" width="16" height="16" />
            </a>
          </div>
          <div className="footer-right">
            <span>
              Web Design &amp; Development by -{" "}
              <a href="http://www.projodesign.co.uk">
                Projo Design | Web Design Newark
              </a>
            </span>
          </div>
        </div>
        <ToTopBtn onClick={() => goToTop()} className="toTopBtn">
          <Icon name="up_arrow" color="#f05c28" width="16" height="16" />
        </ToTopBtn>
      </FooterContainer>
    </>
  )
}

export default Footer
