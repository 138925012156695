import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useSpring, animated } from "react-spring"
import styled, { withTheme, ThemeContext } from "styled-components"
import { Location } from "@reach/router"
import orangeLogo from "../images/mjelectrical-logo-orange.png"
import bgHero from "../images/lead_hero_bg.jpg"
import Nav from "./navigation"

const HeaderWrapper = styled.header`
  /*background: #ef017c;*/
  padding: 20px 0;
  position: relative;
  z-index: 100;
  background: #fff;
  border-bottom: 2px solid ${props => props.theme.orange};

  .inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const MainLogo = styled.img`
  display: block;
  margin-bottom: 0;
  width: 200px;
`

const HomepageLeadImage = styled.img`
  width: 100%;
  margin-top: 100px;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
`

const MenuIcon = styled.div`
  display: none;
  cursor: pointer;
  border: 2px solid ${props => props.theme.orange};
  color: ${props => props.theme.orange};
  padding: 2px 10px;
  border-radius: 5px;
  font-weight: 600;

  @media (max-width: 750px) {
    display: block;
  }
`

const Header = ({ siteTitle, headerImage, location, theme }) => {
  const [isToggled, setToggle] = useState(false)

  return (
    <div>
      <HeaderWrapper location={location}>
        <div className="inner-container">
          <Link to="/">
            <MainLogo src={orangeLogo} alt="MJ Electrical Logo" />
          </Link>
          <Nav setToggle={setToggle} toggleState={isToggled} />
          <MenuIcon onClick={() => setToggle(!isToggled)}>Menu</MenuIcon>
        </div>
      </HeaderWrapper>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default withTheme(Header)
