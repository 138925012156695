import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"
import Icon from "../utilities/icons"

const Nav = styled.nav`
  padding: 0px 0;

  .nav-title-mobile {
    display: none;
  }

  a {
    color: ${props => props.theme.orange};
    display: inline-block;
    margin-left: 18px;
    font-size: 15px;
    /*font-family: "purista-web", sans-serif !important;*/

    text-decoration: none;
    text-transform: uppercase;
    border-bottom: 1px solid transparent;
    &:first-of-type {
      margin-left: 0;
    }
    &:hover {
      border-bottom: 2px solid ${props => props.theme.orange};
    }
    span {
      font-weight: 600;
    }
  }

  .nav-close-btn {
    display: none;
    cursor: pointer;
  }

  @media (max-width: 750px) {
    display: ${props => (props.toggleState ? "block" : "none")};

    background: rgba(255, 255, 255, 0.99);
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    padding: 40px 40px;
    border-radius: 10px;
    z-index: 9999;
    box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);

    .nav-title-mobile {
      display: block;
      color: ${props => props.theme.orange};
      font-weight: 600;
      margin-bottom: 20px;
    }

    a {
      display: block;
      color: #333;
      margin-left: 0;
      padding: 8px 0;
      text-decoration: none;
      font-weight: 600;

      &:hover {
        background: none;
        color: #666;
        border-bottom: 1px solid transparent;
      }

      .special-nav-item {
        border: none;
        padding: inherit;
        &:hover {
          background: none;
          border-bottom: 0;
        }
      }
    }
    .nav-close-btn {
      display: block;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
`

class Navigation extends React.Component {
  render() {
    return (
      <Nav toggleState={this.props.toggleState}>
        <h4 className="nav-title-mobile">Navigation</h4>

        <Link onClick={() => this.props.setToggle(false)} to="/">
          <span>Home</span>
        </Link>
        <Link onClick={() => this.props.setToggle(false)} to="/services">
          <span>Services</span>
        </Link>
        <Link onClick={() => this.props.setToggle(false)} to="/locations">
          <span>Locations</span>
        </Link>
        {/*<Link onClick={() => this.props.setToggle(false)} to="/gallery">
          <span>Gallery</span>
    </Link>*/}
        <Link onClick={() => this.props.setToggle(false)} to="/reviews">
          <span>Reviews</span>
        </Link>
        <Link onClick={() => this.props.setToggle(false)} to="/contact">
          <span>Contact</span>
        </Link>

        <div
          onClick={() => this.props.setToggle(false)}
          className="nav-close-btn"
        >
          <Icon name="close" color="#f05c28" />
        </div>
      </Nav>
    )
  }
}

export default Navigation
